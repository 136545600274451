import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/Kinetik/Navbar";
import Footer from "../components/Kinetik/Footer";
import PageBanner from '../components/Common/PageBanner';
import * as Icon from 'react-feather';
import { Link } from "gatsby";

import BlogImg1 from "../assets/images/blog-image/blog1.jpg";
import BlogImg2 from "../assets/images/blog-image/blog2.jpg";
import BlogImg3 from "../assets/images/blog-image/blog3.jpg";
import BlogImg4 from "../assets/images/blog-image/blog4.jpg";
import BlogImg5 from "../assets/images/blog-image/blog5.jpg";
import BlogImg6 from "../assets/images/blog-image/blog6.jpg";

const Blog1 = () => (
    <Layout>
        <SEO title="Blog" /> 

        <Navbar />

        <PageBanner pageTitle="Rechtliches" />

        <div className="blog-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    {/*<div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg1} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 15, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        The Security Risks of Changing Package Owners
                                    </Link>
                                </h3>

                                <span>By <Link to="/author">Admin</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg2} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 17, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        Tips to Protecting Your Business and Family
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">Smith</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg3} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 19, 2021
                                </div>
                            </div>

                            <div className="blog-post-content"> 
                                <h3>
                                    <Link to="/blog-details">
                                        Protect Your Workplace from Cyber Attacks
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">John</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg4} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 15, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        Here are the 5 most telling signs of micromanagement
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">Admin</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6">
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    <img src={BlogImg5} alt="image" />
                                </Link>

                                <div className="date">
                                    <Icon.Calendar /> March 17, 2021
                                </div>
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        I Used The Web For A Day On A 50 MB Budget
                                    </Link>
                                </h3>
 
                                <span>By <Link to="/author">Smith</Link></span>

                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>

                                <Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>
                            </div>
                        </div>
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">*/}
                        <div className="single-blog-post">
                            <div className="blog-image">
                                <Link to="/blog-details">
                                    {/*<img src={BlogImg6} alt="image" />*/}
                                </Link>

                                {/*<div className="date">
                                    <Icon.Calendar /> March 19, 2021
                                </div>*/}
                            </div>

                            <div className="blog-post-content">
                                <h3>
                                    <Link to="/blog-details">
                                        
                                    </Link>
                                </h3>
 
                                <b>1. Inhalt des Onlineangebotes</b>
                                <p>Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Autors kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
                                <b>2. Verweise und Links </b>
                                <p>Bei direkten oder indirekten Verweisen auf fremde Webseiten (“Hyperlinks”), die außerhalb des Verantwortungsbereiches des Autors liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern.
Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise sowie für Fremdeinträge in vom Autor eingerichteten Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und in allen anderen Formen von Datenbanken, auf deren Inhalt externe Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.</p>
                                <b>3. Urheber- und Kennzeichenrecht </b>
                                <p>Der Autor ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und Texte zurückzugreifen.
Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind!
Das Copyright für veröffentlichte, vom Autor selbst erstellte Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Autors nicht gestattet.</p>
                                <b>4. Datenschutz </b>
                                <p>Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe persönlicher oder geschäftlicher Daten (Emailadressen, Namen, Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme und Bezahlung aller angebotenen Dienste ist – soweit technisch möglich und zumutbar – auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei Verstössen gegen dieses Verbot sind ausdrücklich vorbehalten.</p>
                                <b>5. Rechtswirksamkeit dieses Haftungsausschlusses 
</b>
                                <p>Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.

</p><p></p><p>Disclamer</p><p></p>
                                <b>1. Content </b>
                                <p>The author reserves the right not to be responsible for the topicality, correctness, completeness or quality of the information provided. Liability claims regarding damage caused by the use of any information provided, including any kind of information which is incomplete or incorrect,will therefore be rejected.
All offers are not-binding and without obligation. Parts of the pages or the complete publication including all offers and information might be extended, changed or partly or completely deleted by the author without separate announcement.</p>
                                <b>2. Referrals and links </b>
                                <p>The author is not responsible for any contents linked or referred to from his pages – unless he has full knowledge of illegal contents and would be able to prevent the visitors of his site fromviewing those pages. If any damage occurs by the use of information presented there, only the author of the respective pages might be liable, not the one who has linked to these pages. Furthermore the author is not liable for any postings or messages published by users of discussion boards, guestbooks or mailinglists provided on his page.</p>
                                <b>3. Copyright 
</b>
                                <p>The author intended not to use any copyrighted material for the publication or, if not possible, to indicate the copyright of the respective object.
The copyright for any material created by the author is reserved. Any duplication or use of objects such as images, diagrams, sounds or texts in other electronic or printed publications is not permitted without the author’s agreement.</p>
                                <b>4. Privacy policy </b>
                                <p>If the opportunity for the input of personal or business data (email addresses, name, addresses) is given, the input of these data takes place voluntarily. The use and payment of all offered services are permitted – if and so far technically possible and reasonable – without specification of any personal data or under specification of anonymized data or an alias. The use of published postal addresses, telephone or fax numbers and email addresses for marketing purposes is prohibited, offenders sending unwanted spam messages will be punished.</p>
                                <b>5. Legal validity of this disclaimer </b>
                                <p>This disclaimer is to be regarded as part of the internet publication which you were referred from. If sections or individual terms of this statement are not legal or correct, the content or validity of the other parts remain uninfluenced by this fact.</p>
                                
                                
                                
                               {/*<Link to="/blog-details" className="read-more-btn">
                                    Read More <Icon.ArrowRight />
                                </Link>*/}
                            </div>
                        </div>
                    {/*</div>*/}
                    
                    {/* Pagination */}
                    <div className="col-lg-12 col-md-12">
                        <div className="pagination-area">
                            <nav aria-label="Page navigation">
                                <ul className="pagination justify-content-center">
                                    {/*<li className="page-item">
                                        <Link to="#" className="page-link">
                                            Prev
                                        </Link>
                                    </li>

                                    <li className="page-item active">
                                        <Link to="#" className="page-link">1</Link>
                                    </li>

                                    <li className="page-item">
                                        <Link to="#" className="page-link">2</Link>
                                    </li>

                                    <li className="page-item">
                                        <Link to="#" className="page-link">3</Link>
                                    </li> 

                                    <li className="page-item">
                                        <Link to="#" className="page-link">
                                            Next
                                        </Link>
                                    </li>*/}
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Footer />
    </Layout>
)

export default Blog1;